import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { MaterialModule } from './material.module';
import { AuthGuard }           from './auth.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)    
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./auth/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'tab1-success',
    loadChildren: () => import('./tab1-success/tab1-success.module').then( m => m.Tab1SuccessPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tab1-failed',
    loadChildren: () => import('./tab1-failed/tab1-failed.module').then( m => m.Tab1FailedPageModule),
    canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    // MaterialModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
